import React from 'react';
import './styles.css'; // Asegúrate de que esta importación esté al principio
import logo from './logo.png';
import './App.css';
import Social from './Social';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="pulse-animation" alt="logo" />
        <p>
          ¡Ha llegado el momento! <br></br>Ya está disponible nuestra nueva plataforma
        </p>
        <a href="https://nextdede.tv" target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-3">
          Acceder a NextdedeTV
        </a>

        {/* Agrega el componente MiComponente con los iconos y enlaces */}
        <Social />
      </header>
    </div>
  );
}

export default App;
